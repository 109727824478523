import { Paper, Grid, Typography, List, ListItem} from "@mui/material";
import BrandTypography from './BrandTypography';

function MissionBullet({ title, text }: { title: string, text: string }) {
    return (<ListItem>
        <Typography variant='h5' component='span' display='block' textTransform='none' gutterBottom>
            <BrandTypography variant="h4" fontWeight='bold' >{title}&nbsp;</BrandTypography>
            {text}
        </Typography>
    </ListItem>);
}
export default function SummerCampSection() {
    return (<Paper elevation={1} component='section' square={true} sx={{ marginTop: 8, backgroundColor: 'black', borderTop: '1px solid grey', borderBottom: '1px solid grey' }}>
        <Grid container>
            <Grid item xs={12} p={4} display='flex' flexDirection='column' justifyContent='flex-start' alignItems='left' alignSelf='flex-start'>
                <Typography fontWeight='bold' variant='h2' display='block' textAlign='left' gutterBottom>Empower Fitness Presents: Mental and Physical Fitness Day for Schools, Associations, and Youth!</Typography>
                <Typography variant='h5' paragraph>Hey there, educators, youth leaders, and Gen Z trailblazers! Are you ready to ignite your minds and bodies for a day of empowerment like no other? Empower Fitness is thrilled to extend an invitation to partner with us for an electrifying Mental and Physical Fitness Day that's designed to unleash your full potential!</Typography>
                <Typography variant='h3' display='block' textAlign='left' gutterBottom>Why Partner with Empower Fitness?</Typography>
                <List sx={{
                    // listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <MissionBullet title='Mind and Body Connection:' text="Our event isn't just about breaking a sweat—it's about building mental resilience and physical strength. Through a dynamic blend of mindset training and physical fitness activities, participants will learn how to harness the power of their minds to conquer any challenge." />
                    <MissionBullet title='Expert Guidance:' text="Get ready to be inspired by two powerhouse leaders in the field of mindset and fitness: Rob Eastman and Heidi McNulty. Rob will lead engaging workshops on mindset and goal-setting, while Heidi will take participants through high-energy workouts and wellness activities." />
                    <MissionBullet title='Comprehensive Curriculum:' text="Our Mental and Physical Fitness Day is jam-packed with activities that cater to both the body and mind. From mindset workshops and goal-setting exercises to HIIT workouts and yoga sessions, there's something for everyone to enjoy and learn from." />
                    <MissionBullet title='Community Building:' text="Empower Fitness believes in the power of community. Through team-building activities and group discussions, participants will have the opportunity to connect with like-minded individuals and forge lasting friendships." />
                    <MissionBullet title='Life-Changing Impact:' text="By partnering with Empower Fitness, schools, associations, and youth organizations are investing in the holistic well-being and success of their members. Our goal is to empower participants to become resilient, confident, and unstoppable in pursuit of their goals and dreams." />
                </List>
                <Typography variant='h3' display='block' textAlign='left' gutterBottom>Outline of Mental and Physical Components:</Typography>
                <List sx={{
                    // listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <MissionBullet title='Fitness & Mindset Workshop with Rob Eastman & Heidi Mcnulty:' text="Learn powerful mindset strategies to overcome obstacles, set meaningful goals, and cultivate a winning attitude." />
                    <MissionBullet title='Fitness Sessions:' text="Break a sweat with high-energy workouts and wellness activities led by fitness experts. From strength training to yoga, get ready to feel empowered from the inside out!" />
                    <MissionBullet title='Group Discussions and Team-Building Activities:' text="Foster connections and camaraderie through group discussions and team-building exercises that encourage collaboration and mutual support." />
                    <MissionBullet title='Goal-Setting and Action Planning:' text="Put your newfound knowledge into action by setting personalized goals and creating action plans to achieve them. Empower Fitness will provide resources and guidance to support participants on their journey to success." />
                    </List>
                <Typography fontWeight='bold' variant='h3' display='block' textAlign='left' gutterBottom>Ready to Empower Your Mind and Body?</Typography>
                <Typography variant='h5' paragraph>Partner with Empower Fitness for a Mental and Physical Fitness Day that will leave a lasting impact on your school, association, or youth group. Together, let's inspire, empower, and transform lives one workout and mindset shift at a time. Contact us today to learn more and schedule your event!</Typography>
                            </Grid>
            {/* <Grid item sx={{
                position: 'relative', overflow: 'hidden', maxHeight: '700px', alignContent: 'flex-end', sm: {
                    alignContent: 'flex-start',
                    flexDirection: 'column-reverse',
                    root: {
                        '& div img': {
                            justifyContent: 'flex-end'
                        }
                    },
                    '& .MuiGrid-root .mui-image-wrapper .mui-image': {
                        width: '100%',
                        height: 'auto'
                    } 
            } }} xs={12} sm={6} md={6}>
                <Image fit='fill' style={{ height: '100%', width: 'auto', display: 'flex', justifyContent: 'flex-end' }} src='/Rob_Speaking.png' alt='Rob with a friend at the gym.' />
            </Grid> */}
        </Grid>
    </Paper>)
}