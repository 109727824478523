import { Paper, Grid, Typography, List, ListItem} from "@mui/material";
import BrandTypography from './BrandTypography';
import BrandButton from "./BrandButton";

function PlantBullets({ title, bulletPoints }: { title: string, bulletPoints: string[] }) {
    return (<ListItem>
        <Typography variant='h5' component='span' display='block' gutterBottom>
            <BrandTypography variant="h4" fontWeight='bold' >{title}&nbsp;</BrandTypography>
            <List sx={{
                    listStyleType: 'square',
                    pl: 4,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
            }}>
                {bulletPoints.map((item, index) => <ListItem key={index}><Typography variant='h5' component='span' display='block' gutterBottom>{item}</Typography></ListItem>)}
                </List>
        </Typography>
    </ListItem>);
}
export default function GymPlansSection() {
    return (
      <Paper
        elevation={1}
        component="section"
        square={true}
        sx={{
          marginTop: 8,
          backgroundColor: "black",
          borderTop: "1px solid grey",
          borderBottom: "1px solid grey",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            p={4}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="left"
            alignSelf="flex-start"
          >
            <Typography
              fontWeight="bold"
              variant="h2"
              display="block"
              textAlign="left"
              gutterBottom
            >
              Welcome to Empower Fitness Memberships
            </Typography>
            <Typography variant="h3" paragraph>
              Discover Your Perfect Fit for Fitness Freedom!
            </Typography>
            <List
              sx={{
                // listStyleType: 'disc',
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <PlantBullets
                title="No Commitment, All-Inclusive Membership: $140 Monthly"
                bulletPoints={[
                  "Dive into the world of wellness with our flexible, no-commitment membership.",
                  "Enjoy access to our top-notch facilities and dynamic classes.",
                  "All for just $140 per month.",
                ]}
              />
              <PlantBullets
                title="6-Month Membership: $120 Monthly - Paid in Full"
                bulletPoints={[
                  "Elevate your fitness game with a six-month commitment.",
                  "Benefit from a discounted rate of $120 per month.",
                  "The perfect blend of commitment and flexibility to fit your lifestyle.",
                ]}
              />
              <PlantBullets
                title="12-Month Membership: $100 Monthly - Paid in Full"
                bulletPoints={[
                  "Fully immerse yourself in the Empower Fitness experience.",
                  "Join our community for a full year at an unbeatable rate of $100 per month.",
                  "The ultimate investment in your health and happiness.",
                ]}
              />
              <PlantBullets
                title="Exclusive Founders Membership: $6,000 Lifetime Membership"
                bulletPoints={[
                  "Be part of something special with our Exclusive Founders Membership.",
                  "Secure lifetime access to Empower Fitness with a one-time payment of $6,000.",
                  "Enjoy lifetime wellness perks, discounts on member events, after-hours access, a premium swag package, and your photo on our Wall of Fame.",
                  "Featured in our social media to promote your brand and business.",
                ]}
              />
            </List>
            <BrandTypography textAlign="center" variant="h4" paragraph>
              Ready to take the leap? Join Empower Fitness today and let's
              embark on a journey to greatness together! Choose the membership
              option that speaks to you and let's make every moment count on
              your path to a stronger, happier you.
            </BrandTypography>
          </Grid>
          <Grid
            item
            xs={12}
            mb={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <BrandButton
              href="http://empowerfitness.wodify.com/"
              size="large"
              sx={{
                fontSize: "1.25em",
                minWidth: "225px",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              BECOME A MEMBER
            </BrandButton>
          </Grid>
          {/* <Grid item sx={{
                position: 'relative', overflow: 'hidden', maxHeight: '700px', alignContent: 'flex-end', sm: {
                    alignContent: 'flex-start',
                    flexDirection: 'column-reverse',
                    root: {
                        '& div img': {
                            justifyContent: 'flex-end'
                        }
                    },
                    '& .MuiGrid-root .mui-image-wrapper .mui-image': {
                        width: '100%',
                        height: 'auto'
                    } 
            } }} xs={12} sm={6} md={6}>
                <Image fit='fill' style={{ height: '100%', width: 'auto', display: 'flex', justifyContent: 'flex-end' }} src='/Rob_Speaking.png' alt='Rob with a friend at the gym.' />
            </Grid> */}
        </Grid>
      </Paper>
    );
}