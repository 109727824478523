import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

const BrandTypography = styled(Typography)(({ theme }) => {
    return {
        fontFamily: 'Bree Serif',
        color: theme.palette.primary.main,
    }
});

export default BrandTypography;