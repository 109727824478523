import React from 'react';
import './App.css';
import '@fontsource-variable/oswald';
import '@fontsource/bree-serif';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './Navigation';
import { Box, Grid, List, ListItem, Paper, Typography, responsiveFontSizes, styled } from '@mui/material';
import BrandButton from './BrandButton';
import { products } from './Products';
import ProductCollection from './ProductCollection';
import ExpertiseSection from './ExpertiseSection';
import AboutRobSection from './AboutRobSection';
import BookSection from './BookSection';
import FooterSection from './FooterSection';
import AboutDarienSection from './AboutDarienSection';
import MissionSection from './GymPlansSection';
import SummerCampSection from './SummerCamp';
import ContactPopover from './ContactPopover';


let darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#bc2026'
        }
    },
    typography: {
        fontFamily: ['Oswald Variable', 'sans-serif'].join(','),
        // h1: {
        //     fontSize: '2em',
        // },
        // h2: {
        //     fontSize: '1.75em',
        // },
        // h3: {
        //     fontSize: '1.75em',
        // },
        // h4: {
        //     fontSize: '1.5em',
        // },
        // h5: {
        //     fontSize: '1.5em',
        // },
        // h6: {
        //     fontSize: '1.25em',
        // },
        // body1: {
        //     fontSize: '1.1em',
        // },
        // body2: {
        //     fontSize: '1em',
        // }
    },
    components: {
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: 8,
                    paddingLeft: 10,
                    marginBottom: 4,
                    '& .MuiCardHeader-title': {
                        fontFamily: 'Bree Serif',
                        height: 'fit-content',
                        textTransform: 'uppercase',
                    }
                }
            }
        }
    }
});
darkTheme = responsiveFontSizes(darkTheme);
const GridBgImage = styled(Grid)(({ theme }) => {
    return {
        textAlign: 'center',
        color: 'white',

        minHeight: '400px',
        // maxHeight: '80vh',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
        ":before": {
            content: '" "',
            position: 'absolute',
            backgroundImage: `url(/Rob_Sitting.jpg)`,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: .4,
            backgroundPosition: '50% 0',
        }
    }
});
function App() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const cardRef = React.createRef<HTMLDivElement>();
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const redirectUrl = 'https://empowerfitness.wodify.com/OnlineSalesPortal/Homepage.aspx';
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div id="home" className="App">
          <Navigation />
          <Paper component="section" sx={{ marginTop: "50px" }}>
            <GridBgImage
              sx={{
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh",
              }}
              container
            >
              <Grid item zIndex={3} xs={12} p={4} flexGrow={1}>
                <Typography
                  variant="h1"
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography
                    sx={{ textShadow: "2px 2px 4px black" }}
                    justifySelf="center"
                    alignSelf="center"
                    component="span"
                    variant="h1"
                    gutterBottom
                  >
                    Empowering You Through Fitness & Martial Arts
                  </Typography>
                  <List
                    sx={{
                      maxWidth: "fit-content",
                      alignSelf: "center",
                      listStyleType: "square",
                      textAlign: "center",
                    }}
                  >
                    <ListItem>
                      <Typography
                        justifySelf="flex-end"
                        variant="h4"
                        component="span"
                      >
                        - Specializing in mental health and youth services, we
                        empower Salt Lake City, UT
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        justifySelf="flex-end"
                        variant="h4"
                        component="span"
                      >
                        - Promoting physical strength and mental resilience
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        justifySelf="flex-end"
                        variant="h4"
                        component="span"
                      >
                        - Providing a safe environment to learn and grow
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        justifySelf="flex-end"
                        variant="h4"
                        component="span"
                      >
                        - Fostering a healthier and happier lifestyle
                      </Typography>
                    </ListItem>
                  </List>
                </Typography>
              </Grid>
              <Grid ref={cardRef} item xs={12} mb={5}>
                <BrandButton
                  href="http://empowerfitness.wodify.com/"
                  size="large"
                  sx={{ fontSize: "1.25em", minWidth: "225px" }}
                >
                  BECOME A MEMBER
                </BrandButton>
                <ContactPopover
                  sx={{ marginTop: "24px" }}
                  redirectUrl={redirectUrl}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  cardRef={cardRef}
                />
              </Grid>
            </GridBgImage>
          </Paper>
          <ProductCollection
            title="Featured Fitness Classes"
            productTag="featured"
            products={products}
          />
          <ExpertiseSection />
          <AboutRobSection />
          <AboutDarienSection />
          <BookSection />
          <MissionSection />
          <Box
            mt={8}
            sx={{
              position: "relative",
              height: "2000px",
              width: "100%",
              overflow: "hidden",
              bgcolor: "background.paper",
            }}
          >
            <div
              id="calendar"
              style={{
                position: "absolute",
                marginTop: "-4rem",
                height: "1px",
                width: "1px",
                backgroundColor: "rgba(0,0,0,0)",
              }}
            ></div>
            <Box
              p={2}
              sx={{
                height: "100px",
                width: "100%",
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "black",
              }}
            >
              <Typography fontWeight="bold" variant="h2">
                Calendar
              </Typography>
            </Box>
            <iframe
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Empower Fitness Calendar"
              src="https://app.wodify.com/Schedule/PublicCalendarListView.aspx?tenant=10847"
            ></iframe>
          </Box>
          <SummerCampSection />
          <FooterSection />
        </div>
      </ThemeProvider>
    );
}

export default App;
