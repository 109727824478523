import * as React from 'react';
import ContactForm from './ContactForm';
import { Popover, SxProps } from '@mui/material';
export default function ContactPopover({
    anchorEl, setAnchorEl, cardRef, anchorOrigin, transformOrigin, sx, redirectUrl }:
    {
        anchorEl: HTMLButtonElement | null,
        setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
        cardRef: React.RefObject<HTMLDivElement>,
        anchorOrigin?: { vertical: 'top' | 'center' | 'bottom', horizontal: 'left' | 'center' | 'right' },
        transformOrigin?: { vertical: 'top' | 'center' | 'bottom', horizontal: 'left' | 'center' | 'right' },
        redirectUrl: string,
        sx?: SxProps,
    }) {

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
            <Popover
                slotProps={{ paper: { sx: { minWidth: '350px', ...sx } } }}
                container={cardRef.current}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin ?? {
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={ transformOrigin ?? {
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <ContactForm redirectUrl={redirectUrl} />
            </Popover>
    );
}