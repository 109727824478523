import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const rootElement = document.getElementById('root') as HTMLElement;
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
console.log('Recaptcha Key:', recaptchaKey);
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrateRoot(rootElement, <React.StrictMode>
        <GoogleReCaptchaProvider
            reCaptchaKey={`${recaptchaKey}`}
            useRecaptchaNet={true}
            useEnterprise={false}
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined // optional, default undefined
            }}
        >
            <App />
        </GoogleReCaptchaProvider>
    </React.StrictMode>);
} else {
    ReactDOM
        .createRoot(rootElement)
        .render(<React.StrictMode>
            <GoogleReCaptchaProvider
                reCaptchaKey={`${recaptchaKey}`}
                useRecaptchaNet={true}
                useEnterprise={false}
                scriptProps={{
                    async: false, // optional, default to false,
                    defer: false, // optional, default to false
                    appendTo: 'head', // optional, default to "head", can be "head" or "body",
                    nonce: undefined // optional, default undefined
                }}
            >
                <App />
            </GoogleReCaptchaProvider>
    </React.StrictMode>);

}
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
