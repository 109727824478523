import React from 'react';
import { Paper, Grid, Typography, List, ListItem } from "@mui/material";
import { Image } from 'mui-image';


export default function ExpertiseSection() {
    return (<Paper elevation={1} component='section' square={true} sx={{ marginTop: 8, backgroundColor: 'black', borderTop: '1px solid grey', borderBottom: '1px solid grey' }}>
        <Grid container>
            <Grid item xs={12} sm={6} md={6} p={2} display='flex' flexDirection='column' justifyContent='flex-start' alignItems='left' alignSelf='flex-start'>
                <Typography fontWeight='bold' variant='h2' display='block' textAlign='left' gutterBottom>Our Expertise</Typography>
                <Typography variant='h3' paragraph>Our team of fitness and martial arts experts are here to help you reach your goals. We specialize in:</Typography>
                <List sx={{
                    listStyleType: 'square',
                    pl: 4,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>Teen Lifting & Leadership</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>Self-Defense & Martial Arts</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>Personal Training and Accountability</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>Functional Fitness and Weight Training</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>In-Body Scale Testing and Nutrition</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5' component='span' display='block' gutterBottom>Mental Health Assistance</Typography>
                    </ListItem>
                </List>
            </Grid>
            <Grid item sx={{
                position: 'relative', overflow: 'hidden', maxHeight: '700px', alignContent: 'flex-end', sm: {
                    alignContent: 'flex-start',
                    flexDirection: 'column-reverse',
                    root: {
                        '& div img': {
                            justifyContent: 'flex-end'
                        }
                    },
                    '& .MuiGrid-root .mui-image-wrapper .mui-image': {
                        width: '100%',
                        height: 'auto'
                    } 
            } }} xs={12} sm={6} md={6}>
                <Image fit='fill' style={{ height: '100%', width: 'auto', display: 'flex', justifyContent: 'flex-end' }} src='/Rob_Speaking.png' alt='Rob with a friend at the gym.' />
            </Grid>
        </Grid>
    </Paper>)
}