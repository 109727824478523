import React from 'react';
import { Paper, Grid, Typography } from "@mui/material";
import { Image } from 'mui-image';
import BrandButton from './BrandButton';

export default function BookSection() {
    return (<Paper component='section' elevation={2} square={true} sx={{ marginTop: 8, xs: { flexDirection: 'row-reverse' } }}>
        <Grid container>
            <Grid item xs={12} sm={5} md={8} p={2} pl={4} display='flex' flexDirection='column' justifyContent='space-between'>
                <Typography fontWeight='bold' variant='h2' display='block' gutterBottom>Looking for Inspiration Outside the Gym?</Typography>
                <Typography paragraph>
                    “It’s better to stand and fight. If you run, you will only die tired.” Rob ran from his problems most of his life, letting the feelings of anxiety and depression take over, rather than asking why they were there. He was a gardener in a war, until in recovery he used extreme sports, such as cage fighting, ultramarathons, rock climbing, and skydiving, to face his toughest demons. These life lessons he now uses to coach on how to stand and fight… to be a warrior in the garden.
                </Typography>
                <Typography paragraph>
                <Typography component='span' gutterBottom>
                    “This book takes the reader on an emotional roller coaster, chronicling Rob’s incredible life journey. It is a testament to the power of transformation and demonstrates that with determination and purpose, anyone can overcome addiction and adversity. This honest, raw account of a young man’s struggle and ultimate triumph as a real-life warrior is truly inspiring.”
                </Typography>
                    <Typography component='span' fontStyle='italic' marginBottom={0}>
                    —Lance Essihos, bestselling author, founder of Mic-Up Media, and host of the University of Adversity podcast
                </Typography>
                
                </Typography>
                <Typography paragraph>
                    "Warrior in the garden is a lifeboat for those in similar despair and their families." Michael O. Leavitt, former Secretary of the US Department of Health and Human Services, Former Administrator of the US Environmental Protection Agency, 14th Governor of Utah.
                    </Typography>
                <BrandButton sx={{maxWidth: '100px', margin: '0 auto'}} href='https://www.amazon.com/Warrior-Garden-Rob-Eastman/dp/B0CKGL7H7R' variant='contained'>BUY NOW</BrandButton>
            </Grid>
            <Grid item sx={{ position: 'relative', overflow: 'hidden', minHeight: '500px' }} xs={12} sm={7} md={4}>
                <Image fit='contain' style={{ position: 'absolute', left: 0 }} src='/rob_book.png' alt='Rob with a friend at the gym.' />
            </Grid>
        </Grid>
    </Paper>)
}