import React from 'react';
import { Product } from './Products';
import { Paper, Grid, Typography } from '@mui/material';
import ProductCard from './ProductCard';

export default function ProductCollection({ title, products, productTag }: { title: string, productTag: string, products: Product[] }) {
    return (<Paper component='section' sx={{ marginTop: 8 }}>
        <Grid container p={2} spacing={2} justifyContent='center'>
            <Grid item xs={12}>
                <Typography fontWeight='bold' variant='h2' display='block' gutterBottom>{title}</Typography>
            </Grid>
            {products.filter(p => p.tags.includes(productTag)).map((product, key) => <Grid item xs={12} sm={6} md={4} display='flex' alignContent='center' justifyContent='center' key={key}>
                <ProductCard {...product} />
            </Grid>)}
        </Grid>
    </Paper>)
}