export type Product = { title: string, image: string, imgAlt: string, content: string[], buttonLink: string, tags: string[] }

export const products: Product[] = [
  {
    title: "Group Fitness",
    image: "/Group_Fitness.png",
    imgAlt: "Group Fitness class members group photo at Empower Fitness.",
    content: [
      "We offer instructor-led group fitness classes and professional programming that includes cross training, help with nutrition and accountability, and most importantly a supportive community.",
    ],
    buttonLink: "http://empowerfitness.wodify.com/",
    tags: ["featured", "fanFavorite"],
  },
  {
    title: "Boxing, Kickboxing, Wrestling",
    image: "/kids_boxing_cropped.png",
    imgAlt: "Kids boxing in Robs class.",
    content: [
      "Boxing, Kickboxing & Wrestling offer a full-body workout that enhances strength, cardiovascular health, and agility, while also teaching self-defense techniques.",
    ],
    buttonLink: "http://empowerfitness.wodify.com/",
    tags: ["featured", "fanFavorite"],
  },
  {
    title: "6 Week Challenge - Results Guaranteed!",
    image: "/Kids_Summer_Camp_Group.png",
    imgAlt: "Empower Fitness kids summer camp group photo.",
    content: [
      "We offer a 6 week semi-private training with guaranteed results.",
      "You get nutrition, accountability and fitness all combined for 8-15lbs in weight loss or 2-5% body fat loss guaranteed or we will continue to train you for free! If you get the results, your 6 week challenge payment is applied to a year membership to maintain your results!",
    ],
    buttonLink: "http://empowerfitness.wodify.com/",
    tags: ["featured", "fanFavorite"],
  },
];