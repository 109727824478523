import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import BrandTypography from './BrandTypography';
import { SxProps, Theme, TypographyOwnProps } from '@mui/material';


const ThemedLogo = styled(SvgIcon)(({ theme }) => {
    return {
        //@ts-ignore
        fill: theme.palette.primary.main,
    }
});
export default function Logo({sx}: { sx?: SxProps<Theme> }) {
    return <ThemedLogo sx={sx}>
        <svg id="a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.97 257.78">
            <defs>
            </defs>
            <path d="M31.43,63.5c0-19.43,0-38.87,0-58.3,0-.62-.03-1.25.02-1.87.09-1.21-.21-2.95,1.13-3.27,1.76-.43,2.07,1.48,2.46,2.69,4.1,12.67,8.12,25.37,12.2,38.05,5.87,18.24,11.8,36.46,17.63,54.71,4.89,15.29,9.73,30.6,14.51,45.93,1.29,4.13.65,4.91-3.63,4.62-7.29-.49-14.58-1.08-21.85-1.75-5.12-.47-5.87.08-5.87,5.34-.02,33.26-.02,66.52-.03,99.78,0,1.25.08,2.5,0,3.74-.13,1.85.65,4.19-2.44,4.53-2.87.32-4.79-.51-5.5-3.58-1.36-5.83-3.53-11.4-5.38-17.08-8.33-25.56-16.47-51.18-24.69-76.77-3.01-9.36-6.03-18.71-9.11-28.05-1.75-5.31-1.02-6.31,4.4-5.87,6.79.56,13.58,1.09,20.37,1.58,5.29.39,5.79-.09,5.8-5.39.01-19.68,0-39.37,0-59.05Z" />
        </svg>
    </ThemedLogo>
}

export function LogoWithText({ variant, logoSx }: { variant?: TypographyOwnProps['variant'], logoSx?: SxProps<Theme> }) {
    return <Box sx={{ flexGrow: 1, display: 'flex', flexDirection:'row', justifyItems: 'center', alignItems: 'center', maxWidth: 'fit-content' }}>
        <BrandTypography height='fit-content' color='white !important' variant={variant ?? 'h6'}>EMPOWER</BrandTypography>
        <Logo sx={logoSx} />
        <BrandTypography height='fit-content' color='white !important' variant={variant ?? 'h6'}>FITNESS</BrandTypography>
    </Box>
}