import React from 'react';
import { Paper, Grid, Typography, Link, Box } from "@mui/material";
import { grey } from '@mui/material/colors';
import { LogoWithText } from './Logo';
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ContactForm from './ContactForm';

export default function FooterSection() {
    return (<Paper component='footer' elevation={2} square={true} sx={{ marginTop: 8, xs: { flexDirection: 'row-reverse' } }}>
        <Grid container p={2}>
            <Grid id='find-us' item xs={12} md={4}>
                <Box height='100%'>
                <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', border: `1px solid ${grey[700]}`, height: '100%', boxSizing: 'border-box' }}>
                    <Typography justifySelf='flex-start' variant='h2'>Find Us</Typography>
                    <Box component='address'>
                        <Link sx={{ color: 'white', fontStyle: 'normal' }} href='https://maps.app.goo.gl/7Zxj4ScfThVZGmCz7' target="_blank" display='flex' flexDirection='row' alignItems='center' underline='none'>
                            <PlaceIcon sx={{height: 50, width: 50}} fontSize='large'/> 
                            <Box p={2} display='flex' flexDirection='column'>
                                <Typography variant='h4' component='span'>5320 Wells Park Road STE #3</Typography>
                                <Typography variant='h4' component='span'>West Jordan, UT 84081</Typography>
                            </Box>
                        </Link>
                        <Link sx={{ color: 'white', fontStyle: 'normal'}} href='tel:+8018107055' target="_blank" display='flex' flexDirection='row' alignItems='center' underline='none'>
                            <CallIcon sx={{height: 40, width: 40}} fontSize='large'/> 
                            <Typography sx={{paddingBottom: 1, paddingLeft: 2, margin: 2}} variant='h4' component='span'>(801) 810-7055</Typography>
                        </Link>
                        <Link sx={{ color: 'white', fontStyle: 'normal'}} href='mailto:EmpowerFitU@gmail.com' target="_blank" display='flex' flexDirection='row' alignItems='center' underline='none'>
                            <EmailIcon sx={{height: 40, width: 40}} fontSize='large'/> 
                            <Typography sx={{ paddingBottom: 1, paddingLeft: 2, margin: 2 }} variant='h4' component='span'>EmpowerFitU@gmail.com</Typography>
                        </Link>
                    </Box>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} md={4} p={2} display='flex' flexDirection='row' justifyContent='center' alignItems='flex-start'>
                <LogoWithText variant='h3' logoSx={{height: 60, width: 60}} />
            </Grid>
            <Grid id='contact-us' item xs={12} md={4}>
                <Box height='100%'>
                    <ContactForm/>
                </Box>
            </Grid>
        </Grid>
    </Paper>)
}