import React from 'react';
import { Paper, Grid, Typography, List, ListItem } from "@mui/material";
import { Image } from 'mui-image';
import BrandTypography from './BrandTypography';

export default function AboutRobSection() {
    return (<Paper component='section' elevation={2} square={true} sx={{ marginTop: 8, xs: { flexDirection: 'row-reverse', position: 'relative' } }}>
        <Grid container>
            <Grid item sx={{ position: 'relative', overflow: 'hidden', minHeight: '350px' }} xs={12} sm={7} md={4}>
                <div id='about-rob' style={{
                    position: 'absolute',
                    marginTop: '-4rem',
                    height: '1px',
                    width: '1px',
                    backgroundColor: 'rgba(0,0,0,0)'
                }}></div>
                {/* @ts-ignore */}
                <Image fit='fill' sx={{
                    xs: {
                        width: '100%',
                        height: 'auto'
                    }
                }} style={{position: 'absolute', left: 0, height: 'auto', width: '100%'}} src='/Rob_Flexing.jpg' alt='Rob with a friend at the gym.' />
            </Grid>
            <Grid item xs={12} sm={5} md={8} p={2} pl={4}>

                <Typography fontWeight='bold' variant='h2' display='block' gutterBottom>Meet Rob</Typography>
                <BrandTypography variant='h4' paragraph>Gym owner, entrepreneur, father, life coach, and motivational speaker.</BrandTypography>
                <Typography paragraph gutterBottom>Rob’s been in that dark place, on the dark side of addiction and he knows it’s not easy. He’s here to help and guide you to the other side, by your side. He can help you develop the mindset and strategies to dig yourself out of any situation or take your life to the next level.</Typography>
                <Typography marginBottom={0} paragraph>Rob has been through a lot in his life and has come out the other side stronger and more determined than ever. Some of Rob's accomplishments:</Typography>
                <List sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Placed top 150 at the 2012 World Championship of obstacle racing</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Ran over 13 ultra marathons</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Started fighting in MMA & boxing at the age of 35</Typography>
                    </ListItem>
                </List>
                <Typography marginBottom={0} paragraph>Rob has been invited to share his fitness and recovery story all over Utah and the nation at places such as:</Typography>
                <List sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>The National Rotary Convention</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Utah State University Keynote</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Utah P.E. and Health Teacher State Conference Keynote</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Traveled and been to Mt. Everest Base Camp</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body2' component='span' display='block'>Lectured at BYU to the Masters Level Psychology Students</Typography>
                    </ListItem>
                </List>
                <Typography paragraph>Please get in touch, fill in the contact form and we can arrange a call and see how I can help you.</Typography>
            </Grid>
        </Grid>
    </Paper>)
}