import styled from '@emotion/styled';
import Button from '@mui/material/Button';

const BrandButton = styled(Button)(({ theme }) => {
    return {
        //@ts-ignore
        backgroundColor: theme.palette.primary.main,
        //@ts-ignore
        color: theme.palette.primary.contrastText,
        borderRadius: 0,
        cursor: 'pointer',
        fontFamily: 'Bree Serif',
        '&:hover': {
            //@ts-ignore
            backgroundColor: theme.palette.primary.dark,
        },
    };
});

export default BrandButton;