import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { LogoWithText } from './Logo';
import BrandButton from './BrandButton';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [{ label: 'Home', href: '/#home' }, { label: 'Calendar', href: '/#calendar' }, {label: 'About', href: '/#about-rob'}, {label: 'Contact', href: '/#contact-us'}];

export default function DrawerAppBar(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const cardDrawerRef = React.createRef<HTMLDivElement>();
    const cardNavRef = React.createRef<HTMLDivElement>();

    const drawer = (
        <Box ref={cardDrawerRef} onClick={handleDrawerToggle} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', position:'sticky', alignItems: 'center', zIndex: 9999 }}>
            <LogoWithText />
            <Divider />
            <List sx={{paddingTop: '30px'}}>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton href={item.href} sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
                {/* <ListItem><BrandButton onClick={handleDrawerClick}>SIGN UP</BrandButton></ListItem> */}
            </List>
            {/* <ContactPopover redirectUrl={redirectUrl} sx={{marginTop: '32px'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'bottom', horizontal: 'center'}} anchorEl={anchorDrawerEl} setAnchorEl={setDrawerAnchorEl} cardRef={cardDrawerRef} /> */}
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          zIndex: 9999,
          alignItems: "center",
        }}
      >
        <AppBar
          component="nav"
          sx={{ height: "50px", justifyContent: "center" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <LogoWithText />
            <Box ref={cardNavRef} ml="auto">
              {navItems.map((item) => (
                <Button
                  href={item.href}
                  key={item.label}
                  sx={{ display: { xs: "none", sm: "inline", color: "white" } }}
                >
                  {item.label}
                </Button>
              ))}
              <BrandButton
                href="http://empowerfitness.wodify.com/"
                sx={{ marginLeft: 2 }}
                variant="contained"
              >
                SIGN UP
              </BrandButton>
            </Box>
            {/* <ContactPopover
              redirectUrl={redirectUrl}
              sx={{ marginTop: "32px" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "bottom", horizontal: "center" }}
              anchorEl={anchorNavEl}
              setAnchorEl={setNavAnchorEl}
              cardRef={cardNavRef}
            /> */}
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    );
}