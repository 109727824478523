import React from 'react';
import { Paper, Grid, Typography } from "@mui/material";
import { Image } from 'mui-image';
import BrandTypography from './BrandTypography';

export default function AboutDarienSection() {
    return (<Paper id='about-darien' component='section' elevation={2} square={true} sx={{ marginTop: 8, xs: { flexDirection: 'row-reverse' } }}>
        <Grid container>
            <Grid item xs={12} sm={5} md={8} p={2} pl={4}>
                <Typography fontWeight='bold' variant='h2' display='block' gutterBottom>Meet Darien</Typography>
                <BrandTypography variant='h4' paragraph>Gym Owner, MMA Coach and Fighter, Youth Mentor, Mental Health Advocate</BrandTypography>
                <Typography paragraph>Having Dealt with bullying his entire life Darien learned the skills he needed to have confidence in himself and inspire others to be their authentic selves. From the choir to the theater to the cage Darien has always carved his own path and guided others the whole way.</Typography>
                <Typography paragraph>Working hard to not only achieve his dreams, but to lead others Darien has won many awards for singing, acting, and competing in combat sports and climbed the rankings to be one of the highest ranked featherweights in the state. He has inspired many others to pursue their own dreams and take on martial arts training under his guidance. He has spent many years as a youth mentor in public schools working with kids in groups or one on one from all walks of life. </Typography>
                <Typography paragraph>Please reach out if there is anything we can help you with.</Typography>
            </Grid>
            <Grid item sx={{ position: 'relative', overflow: 'hidden', minHeight: '350px' }} xs={12} sm={7} md={4}>
                <Image fit='contain' style={{position: 'absolute', left: 0, width: '100%', height: 'auto' }} src='/About_Darien.png' alt='Rob with a friend at the gym.' />
            </Grid>
        </Grid>
    </Paper>)
}