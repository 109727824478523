import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import BrandButton from './BrandButton';
import { Product } from './Products';


export default function ProductCard({ title, image, imgAlt, content, buttonLink }: Product) {
    const labelById = Math.floor(Math.random() * 1000);
    const labelId = `card-header-${labelById}`;
    const descriptionId = `card-description-${labelById}`;
    const cardRef = React.createRef<HTMLDivElement>();
    return (
        <Card ref={cardRef} elevation={3} sx={{ width: '100%', maxWidth: '500px', minHeight: '500px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <CardHeader id={labelId}
                title={title}
            />
            <CardMedia
                component="img"
                height="194"
                image={image}
                alt={imgAlt}
            />
            <CardContent id={descriptionId}>
                {content.map((paragraph, key) =>
                    <Typography key={key} variant="body2" color="text.secondary">
                        {paragraph}
                    </Typography>)}
            </CardContent>
            <CardActions sx={{alignItems: 'center', justifyContent: 'center', marginTop: 'auto'}} disableSpacing >
                <BrandButton size='large' sx={{ fontSize: '1.25em', minWidth: '200px' }} href={buttonLink}>
                    Sign Up
                </BrandButton>
            </CardActions>
        </Card>
    );
}
