import { Paper, Typography, TextField, Divider, SxProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { FormEvent, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LoadingButton } from '@mui/lab';
type Form = {
    name: string;
    email: string;
    phone: string;
    message: string;
};
type Errors = {
    name: string | null;
    email: string | null;
    phone: string | null;
    message: string | null;
    submit: string | null;
};
export default function ContactForm({ redirectUrl, sx }: { redirectUrl?: string, sx?: SxProps }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const theme = useTheme();
    const [submittingForm, setSubmittingForm] = useState(false);
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    console.log('sx', sx);
    const [errors, setErrors] = useState<Errors>({
        name: null,
        email: null,
        phone: null,
        message: null,
        submit: null
    });
    const validate = (form: Form): boolean => {
        const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let name = null;
        let email = null;
        let phone = null;
        let message = null;
        if (form.name === '') name = 'Name is required';
        if (!phoneRegex.test(form.phone)) phone = 'Please enter a valid 10 digit phone number';
        if (form.email !== '' && !emailRegex.test(form.email)) email = 'Please enter a valid email address';
        if (form.message === '') message = 'Message is required';
        setErrors({
            name,
            email,
            phone,
            message,
            submit: null
        });
        return name === null && phone === null && email === null && message === null;
    }

    const submitForm = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const isValid = validate(form);
        if(!isValid) return;
        const submit = async () => {
            console.log('getting token...');
            setSubmittingForm(true);
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                setSubmittingForm(false);
                return;
            }
            const apiUrl = process.env.REACT_APP_API_URL;
            console.log('apiUrl:', apiUrl);
                const token = await executeRecaptcha('contact_form');
                await fetch(`${apiUrl}/verify/${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form),
                });
            setSubmittingForm(false);
            setErrors({ ...errors, submit: 'An error occurred. Please try again later or contact us directly.' });

        }
        submit().then(() => {
            console.log('submitted');
            setForm({
                name: '',
                email: '',
                phone: '',
                message: ''
            
            });
            setErrors({ ...errors, submit: null });
            setSubmittingForm(false);
            if(redirectUrl) window.location.assign(redirectUrl);
        }).catch((err) => {
            console.error(err);
            setSubmittingForm(false);
            setErrors({ ...errors, submit: 'An error occurred. Please try again later or contact us directly.' });
        });
    }

    return (<Paper component='form' sx={{
        padding: 2,
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: `1px solid ${grey[700]}`,
        ...sx
    }}>
        <Typography justifySelf='flex-start' variant='h2'>Contact Us</Typography>
        <Typography sx={{height: '1em'}} color='error'>{errors.name ?? ''}</Typography>
        <TextField required fullWidth id="name" margin='normal' label="Name" variant="outlined" value={form.name} onChange={(e) => {
            if(e.target.value !== '') setErrors({...errors, name: null});
            setForm({ ...form, name: e.target.value })
        }} />
        <Typography sx={{height: '1em'}} color='error'>{errors.phone ?? ''}</Typography>
        <TextField required fullWidth id="phone" margin='normal' label="Phone Number" variant="outlined" value={form.phone} onChange={(e) => {
            if(e.target.value !== '') setErrors({...errors, phone: null});
            setForm({ ...form, phone: e.target.value });
        }} />
        <Typography sx={{height: '1em'}} color='error'>{errors.email ?? ''}</Typography>
        <TextField fullWidth id="email" margin='normal' label="Email" variant="outlined" value={form.email} onChange={(e) => {
            if(e.target.value !== '') setErrors({...errors, email: null});
            setForm({ ...form, email: e.target.value });
        }} />
        <Typography sx={{height: '1em'}} color='error'>{errors.message ?? ''}</Typography>
        <TextField required fullWidth multiline id="message" rows={4} margin='normal' label="Message" variant="outlined" value={form.message}
            onChange={(e) => {
                if(e.target.value !== '') setErrors({...errors, message: null});
                setForm({ ...form, message: e.target.value })
            }} />
        <Divider sx={{ marginY: 2 }} />
        {/* <GoogleReCaptcha onVerify={onVerify} /> */}
        <Typography sx={{ height: '1em', marginBottom: '1em' }} color='error'>{errors.submit ?? ''}</Typography>
        <LoadingButton loading={submittingForm} sx={{
            maxWidth: 200,
            //@ts-ignore
            backgroundColor: theme.palette.primary.main,
            //@ts-ignore
            color: theme.palette.primary.contrastText,
            borderRadius: 0,
            cursor: 'pointer',
            fontFamily: 'Bree Serif',
            '&:hover': {
                //@ts-ignore
                backgroundColor: theme.palette.primary.dark,
            },
        }} onClick={submitForm}>{(() => submittingForm ? <span>Sending...</span> : <span>Submit</span>)()}</LoadingButton>
    </Paper>)
}